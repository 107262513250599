
export const Applications: React.FC<{}> = () => {
  return (
    <>
        <div className="container">
            <div className="row">
            <div className="col">
                <h1>Applications</h1>
                <p>Applications are currently closed.</p>
            </div>
            </div>
        </div>
    </>
  );
};