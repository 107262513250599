import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './LandingPage.css';
import Lottie from 'lottie-react';
import dragonData from '../dragon2.json';
import { Contact } from './Contact';
/*
import Callback from './Callback';  // Import the Callback component
import PrivateRoute from './PrivateRoute';
import { Login } from './Login';
import { Applications } from './Applications';
*/
export const LandingPage: React.FC<{}> = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [isPortrait, setIsPortrait] = useState(screenHeight >= screenWidth);
    const [isDesktop, setIsDesktop] = useState(!/Mobi|Android/i.test(navigator.userAgent));

    const updateScreenDimensions = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
        setIsPortrait(window.innerHeight >= window.innerWidth);
      };
    
      useEffect(() => {
        const updateOrientation = () => setIsPortrait(window.innerHeight >= window.innerWidth);
        updateScreenDimensions();
        window.addEventListener('resize', updateOrientation);
        return () => window.removeEventListener('resize', updateOrientation);
      }, []);
    
      useEffect(() => {
        window.addEventListener('resize', updateScreenDimensions);
        return () => window.removeEventListener('resize', updateScreenDimensions);
      }, []);
/*<div className="app-header">
        <div className="login-button-container">
            <Link to="/login" className="login-button">Login</Link>
        </div>
      </div>
      */
  return (
    <>
      <div className="app-container">
  <div className={isPortrait ? 'portrait-container' : 'landscape-container'}>
    <img
      src={isPortrait ? '../fantasy4.jpg' : '../fantasyLandscape.jpg'}
      alt="background"
      className="background-image"
    />

    <div className={isPortrait ? 'banner' : 'banner-landscape'}>
      <div className={isPortrait ? 'banner-box' : 'banner-box-landscape'}>
        <h1 className={isPortrait ? 'title' : 'title-landscape'}>Unleash Epic Adventures</h1>
        <h2 className={isPortrait ? 'subtitle' : 'subtitle-landscape'}>Maximize Creativity, Minimize Prep Time</h2>
        <p className={isPortrait ? 'description' : 'description-landscape'}>
        Cut down prep time while immersing players in rich, memorable worlds.
        </p>
        <p className={isPortrait ? 'description' : 'description-landscape'}>
          Dungeon Drawer transforms your ideas into immersive encounters and vibrant worlds with minimal effort.
        </p>
        <p className={isPortrait ? 'launch' : 'launch-landscape'}> Launching Soon - Get Ready to Transform Your Campaigns! </p>
        <div className={isPortrait ? 'feature-list' : 'feature-list-landscape'}>
          <div className={isPortrait ? 'feature-item' : 'feature-item-landscape'}>
            <span>🛠️ Dungeon Designer</span> —  Design intricate dungeons with ease and print to scale.
          </div>
          <div className={isPortrait ? 'feature-item' : 'feature-item-landscape'}>
            <span>🎲 Ready-to-Use Encounter Builder</span> — Quickly build encounters tailored to your campaign's difficulty with ready-to-use options. 
          </div>
          <div className={isPortrait ? 'feature-item' : 'feature-item-landscape'}>
            <span>📝 Initiative Tracker</span> — Track creature health, conditions, and turns effortlessly during gameplay.
          </div>
          <div className={isPortrait ? 'feature-item' : 'feature-item-landscape'}>
            <span>🎨 AI-Generated Creatures & Scenes</span> — Instantly populate your world with AI-crafted creature and scene art.
          </div>
        </div>
        <Contact/>
      </div>
    </div>
    <div className={`dragon-container ${isPortrait ? 'portrait' : 'landscape'} ${isDesktop ? 'computer' : 'mobile'}`} id="dragon">
  <Lottie animationData={dragonData} className="dragon" />
</div>
  </div>
</div>
    </>
  );
};