import React, { useEffect } from 'react';
import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import { useNavigate } from 'react-router-dom';
/*
const authData = {
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  AppWebDomain: process.env.REACT_APP_COGNITO_APP_DOMAIN || '',
  TokenScopesArray: ['email', 'openid', 'profile'],
  RedirectUriSignIn: 'https://dungeondrawer.com/callback',
  RedirectUriSignOut: 'https://dungeondrawer.com/logout',
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
};
*/
const authData = {
  ClientId: '1eq67rjl0gnk6d7ncdbcsh8bah',
  AppWebDomain: 'auth.dungeondrawer.com',
  TokenScopesArray: ['email', 'openid', 'profile'],
  RedirectUriSignIn: 'https://auth.dungeondrawer.com/callback',
  RedirectUriSignOut: 'https://auth.dungeondrawer.com/logout',
  UserPoolId: 'us-east-2_IOPetU7rE',
};

const auth = new CognitoAuth(authData);

const Callback = () => {
  const navigate = useNavigate(); // Use React Router to navigat

  useEffect(() => {
    auth.userhandler = {
      onSuccess: function (result: CognitoAuthSession) {
        // Store tokens in sessionStorage
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();

        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('idToken', idToken);

        // Decode token to get user details
        const idTokenPayload = result.getIdToken().decodePayload() as { email: string; name: string; 'custom:role': string };
        const user = {
          email: idTokenPayload.email,
          name: idTokenPayload.name,
          role: idTokenPayload['custom:role'], // assuming a custom role is present
        };

        // Store user in sessionStorage
        sessionStorage.setItem('user', JSON.stringify(user));

        // Navigate to home page after successful login
        navigate('/');
      },
      onFailure: function (err: any) {
        console.error('Authentication error:', err);
        navigate('/signin'); // Redirect to sign-in if there's an error
      }
    };

    // This is necessary to complete the authentication flow after redirection
    auth.parseCognitoWebResponse(window.location.href);
  }, [navigate]);

  return <div>Loading...</div>; // Show a loading message while authentication is processing
};

export default Callback;
