import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Callback from './components/Callback';  // Import the Callback component
import { Login } from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { Applications } from './components/Applications';
import { LandingPage } from './components/LandingPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage  />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/login" element={<Login  />} />
        <Route path="/no-access" element={<div>Access Denied</div>} />
        <Route path="/apps" element={<PrivateRoute requiredRole="GrantedUser" fallbackRoute="/no-access"><Applications  /></PrivateRoute>} />
        <Route path="/apps/dungeondrawer" element={<PrivateRoute requiredRole="GrantedUser" fallbackRoute="/no-access"><div>Dungeon Drawer</div></PrivateRoute>} />
        <Route path="/apps/initativetracker" element={<PrivateRoute requiredRole="GrantedUser" fallbackRoute="/no-access"><div>Initative Tracker</div></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute requiredRole="GrantedUser" fallbackRoute="/no-access"><div>Settings Page</div></PrivateRoute>} />
        <Route path="/management" element={<PrivateRoute requiredRole="AdminUser" fallbackRoute="/no-access"><div>Mangement Page</div></PrivateRoute>} />
        <Route path="*" element={<div>404 Not Found</div>} />
        {/* Add Management Component, Update PrivateRoute to be requiredRoles (has to be one of the following) */}
      </Routes>
    </Router>
  );
};

export default App;
/*
<Route path="/contact" element={<div>Contact Page</div>} />
        <Route path="/about" element={<div>About Page</div>} />
        <Route path="/blog" element={<div>Blog Page</div>} />



<div className={isPortrait ? 'feature-item' : 'feature-item-landscape'}>
            <span>👹 AI-Generated Creatures & Scenes</span> — Bring your adventures to life instantly with custom AI-crafted visuals.
          </div>
        </div>
        <Contact/>
      </div>
    </div>
    <div className={`dragon-container ${isPortrait ? 'portrait' : 'landscape'} ${isDesktop ? 'computer' : 'mobile'}`} id="dragon">
  <Lottie animationData={dragonData} className="dragon" />
</div>*/