import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { ReactNode } from 'react';

interface PrivateRouteProps {
  children: ReactNode;
  requiredRole?: string; // You can optionally pass a required role
  fallbackRoute?: string; // Custom route for redirection if user is not authenticated or doesn't have the correct role
}

function PrivateRoute({ children, requiredRole = "GrantedUser", fallbackRoute = "/signin" }: PrivateRouteProps) {
  const authContext = useContext(AuthContext); // Access user data from context

  // If user is not authenticated, redirect to the fallback route (default: /signin)
  if (!authContext || !authContext.user) {
    return <Navigate to={fallbackRoute} />;
  }

  const { user } = authContext;

  // If user is authenticated and role matches, render the child components
  return user.role === requiredRole ? (
    <>{children}</>
  ) : (
    <Navigate to={fallbackRoute} />
  );
}

export default PrivateRoute;
