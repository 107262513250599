import { useState } from "react";

export const ContactFormOverlay: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('https://api.dungeondrawer.com/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
                mode: 'cors'
            });

            if (!response.ok) {
                throw new Error('Signup failed. Please try again.');
            }

            setEmail('');
            setSuccess(true); // Show success message
            setTimeout(() => {
                onClose(); // Close overlay after delay
            }, 3000); // Adjust delay as needed (e.g., 2000 ms for 2 seconds)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('An unknown error occurred.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <div className="overlay">
        <div className="overlay-content">
          <h2 className="overlay-title">Join the Adventure</h2>
          <p>Sign up for updates on Dungeon Drawer and be the first to know when we go live!</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
              className="input-field"
            />
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">Signup successful! Thank you!</p>}
            <div className="button-group">
              <button type="submit" disabled={isLoading} className="close-btn">
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
              <button type="button" onClick={onClose} className="close-btn">Close</button>
            </div>
          </form>
        </div>
      </div>
    );
};

const ContactCard: React.FC = () => {
    const [showContactForm, setShowContactForm] = useState(false);

    const openContactForm = () => setShowContactForm(true);
    const closeContactForm = () => setShowContactForm(false);

    return (
      <>
        <button onClick={openContactForm} className="cta-button">
          Join the Adventure!
        </button>
        {showContactForm && <ContactFormOverlay onClose={closeContactForm} />}
      </>
    );
};

export default ContactCard;
