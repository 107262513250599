import React from 'react';
import ContactCard, {
  ContactFormOverlay,
} from '../components/ContactFormOverlay';
export const Contact: React.FC<{}> = () => {
  return (
    <>
      <ContactCard />
    </>
  );
};