import React, { useEffect } from 'react';

export const Login: React.FC<{}> = () => {
  useEffect(() => {
    // Redirect to the Cognito hosted UI login
    const loginUrl = "https://auth.dungeondrawer.com/login?client_id=1eq67rjl0gnk6d7ncdbcsh8bah&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2Fauth.dungeondrawer.com%2Fcallback";
    window.location.href = loginUrl;
  }, []);

  return (
    <div>Redirecting to login...</div>
  );
};
